
  export default {
    name: "PasswordField",
    props: [
      "id",
      "label",
      "password",
      "label_classes",
      'classes',
      'placeholder',
      'no_check_pattern',
      'focus'
    ],
    model: {
      prop: 'password',
      event: 'update:value'
    },
    data() {
      return {
        showPassword: false,
      };
    },
    mounted() {
      if (this.focus === true) {
        this.$refs.password.focus()
      }
    },
    methods: {
      togglePassword(event) {
        event.preventDefault()
        this.showPassword = !this.showPassword
      },
    }
  }
